import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/config.js";
import { useNavigate, useParams } from "react-router-dom";

import dots from "../images/3dots.svg";

import { encryptText, decryptText } from "../components/crypto.js";

export default function UserVideo() {
    const [details, setDetails] = useState([]);
    const [isOpen, setIsOpen] = useState({}); // Initialize as an object
    const { username } = useParams();

    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const logged_username = storedProfile?.username;

    useEffect(() => {
        axios
            .get(`${API_BASE_URL}videos/${username}`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem("authToken")}`,
                },
            })
            .then((res) => {
                setDetails(res.data);
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                // You might want to handle this error more gracefully in your UI
            });
    }, []); // Empty dependency array means this effect runs only once after the initial rendering

    const handleDelete = (videoId) => {
        axios
            .delete(`${API_BASE_URL}video/${videoId}/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem("authToken")}`,
                },
            })
            .then((res) => {
                console.log("Video deleted:", res.data);
                // Refresh the list after deleting
                setDetails(details.filter((item) => item.id !== videoId));
            })
            .catch((err) => {
                console.error("Error deleting video:", err);
                // Handle the error more gracefully
            });
    };

    const toggleDropdown = (id) => {
        setIsOpen((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <>
            <div className="user-video-container">
                <div className="video-items">
                    {details.map((output, id) => (
                        <div className="video-div-userprofile" key={id}>
                            <a
                                id="video-link-item"
                                href={`/watch/${output.id}`}
                            >
                                <img
                                    id="video-thumbail"
                                    src={`${
                                        output.thumbnail.startsWith("/https:/")
                                            ? output.thumbnail.replace(
                                                  "/https:/",
                                                  "https://"
                                              )
                                            : output.thumbnail
                                    }`}
                                    alt="video thumbnail"
                                />

                                <h1 id="video-title">{output.title}</h1>
                            </a>
                            {storedProfile.username === username && (
                                <button
                                    className="dots-button-video"
                                    onClick={() => toggleDropdown(output.id)}
                                >
                                    <div className="dots-container-video">
                                        <img src={dots} alt="Menu" />
                                    </div>
                                </button>
                            )}
                            {storedProfile.username === username &&
                                isOpen[output.id] && (
                                    <div className="dropdown-menu-video">
                                        <ul>
                                            <li className="edit-menu-delete">
                                                <button
                                                    id="edit-profile-button"
                                                    onClick={() =>
                                                        handleDelete(output.id)
                                                    }
                                                >
                                                    Delete Video
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
