import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/forgot.css";
import { API_BASE_URL } from "../components/config.js";

export default function Forgot() {
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}auth/users/reset_password_confirm/`,
        {
          uid,
          token,
          new_password: newPassword,
          headers: {
            Authorization: `JWT ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setResetMessage("Password has been reset successfully.");
      setTimeout(() => {
        navigate("/login");
      }, 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Password reset failed:", error.response);
    }
  };

  return (
    <div className="body-container">
      <div className="inside-body">
        <div className="forgot-password-container">
          <h2 id="reset-password">Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <div className="forgot-input-div">
              <div className="forgot-password">
                <label htmlFor="newPassword" className="fs-md input-text">
                  New Password
                </label>
                <input
                  className="user-info-input"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="forgot-password">
                <label htmlFor="confirmPassword" className="fs-md input-text">
                  Confirm Password
                </label>
                <input
                  className="user-info-input"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="reset-password-container">
              <button id="reset-button" type="submit">
                Reset Password
              </button>
            </div>
          </form>
          {error && <p className="error-message">{error}</p>}
          {resetMessage && <p className="reset-message">{resetMessage}</p>}
        </div>
      </div>
    </div>
  );
}
