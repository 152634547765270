import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/config";

import Navbar from "../components/navbar";
import MobileNavbar from "../components/mobile-navbar.js";
import LeftBar from "../components/leftbar";
import BackHome from "../components/backhome";
import { useNavigate } from "react-router-dom";
import aclesia_logo from "../images/aclesia-logo.png";
import churchicon from "../images/church.svg";
import create_icon from "../images/create.svg";
import "../styles/churches.css";
import { encryptText, decryptText } from "../components/crypto.js";
import Spinner from "../components/spinner";

export default function Churches() {
    const [videos, setVideos] = useState([]);
    const [profiles, setProfiles] = useState({});
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    const navigate = useNavigate();
    const storedProfileStr = localStorage.getItem("userProfile");
    let storedProfile;

    try {
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const username = storedProfile?.username;
    const token = localStorage.getItem("authToken");

    // Define fetchProfileData function before its first use
    const fetchProfileData = (userId) => {
        if (!profiles[userId] && token) {
            axios
                .get(`${API_BASE_URL}profile/${userId}`, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                })

                .then((res) => {
                    setProfiles((prev) => ({ ...prev, [userId]: res.data }));
                })
                .catch((err) =>
                    console.error(
                        `Error fetching profile data for user ID ${userId}:`,
                        err
                    )
                );
        }
    };

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return; // Stop execution if there's no token
        }

        const fetchProfile = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL}profile/${storedProfile.id}`,
                    {
                        headers: {
                            Authorization: `JWT ${localStorage.getItem(
                                "authToken"
                            )}`,
                        },
                    }
                );
                // console.log(response.data);
                setProfile(response.data);
            } catch (error) {
                console.error("Failed to fetch profile", error);
                if (error.response && error.response.status === 404) {
                    navigate("/not-found");
                }
            }
        };

        fetchProfile();
    }, [username, token, navigate]);

    useEffect(() => {
        axios
            .get(`${API_BASE_URL}video/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem("authToken")}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                setVideos(res.data);
                res.data.forEach((video) => {
                    if (video.user) {
                        fetchProfileData(video.user);
                    }
                });
            })
            .catch((err) => console.error("Error fetching video data:", err))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <>
                <div className="loading-screen"></div>
                <Spinner />
            </>
        );
    }

    if (!profile) {
        return (
            <>
                <div className="loading-screen"></div>
                <Spinner />
            </>
        );
    }

    const url_tag = profile.user_type === "user" ? "user" : "church";
    return (
        <>
            <Navbar className="navbar" />
            <LeftBar className="leftbar" />
            <MobileNavbar />

            <div className="user-profile-container-churches">
                <BackHome className="backhome" />
                <div className="video-items-churches">
                    {videos.map((video, id) => (
                        <div className="video-div-churches" key={id}>
                            <a id="video-link-item" href={`/watch/${video.id}`}>
                                <img
                                    id="video-thumbail"
                                    src={`${
                                        video.thumbnail.startsWith("/https:/")
                                            ? video.thumbnail.replace(
                                                  "/https:/",
                                                  "https://"
                                              )
                                            : video.thumbnail
                                    }`}
                                    alt="video thumbnail"
                                />

                                {video.user && profiles[video.user] && (
                                    <div className="other-user-info-churches">
                                        <div></div>
                                        <img
                                            id="user-profile-img-churches"
                                            src={`${
                                                profiles[
                                                    video.user
                                                ]?.profile_img.startsWith(
                                                    "/https:/"
                                                )
                                                    ? profiles[
                                                          video.user
                                                      ]?.profile_img.replace(
                                                          "/https:/",
                                                          "https://"
                                                      )
                                                    : profiles[video.user]
                                                          ?.profile_img
                                            }`}
                                            alt="profile"
                                        />

                                        <p id="user-profile-txt-churches">
                                            {profiles[video.user].username}
                                        </p>
                                    </div>
                                )}
                                <h1 id="video-title-churches">{video.title}</h1>
                                {/* Make sure you're using the correct ID to access the profile data */}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <footer className="bottom-navbar-small">
                <ul className="bottom-nav-ul">
                    <a href="/churches" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-church" src={churchicon} />
                        </li>
                    </a>
                    <a href="/create" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-create" src={create_icon} />
                        </li>
                    </a>
                    <a
                        href={`/${url_tag}/${username}`}
                        className="bottom-nav-item"
                    >
                        <li>
                            <img
                                width="50"
                                height="50"
                                id="bottom-nav-profile"
                                src={`${
                                    profile.profile_img.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile.profile_img
                                }`}
                                alt="Profile"
                            />
                        </li>
                    </a>
                </ul>
            </footer>
        </>
    );
}
