import React, { useState } from 'react';
import axios from 'axios';
import '../styles/register.css';
import aclesia_logo from '../images/aclesia-logo.svg';
import { API_BASE_URL } from '../components/config.js';

function getCsrfToken() {
    // Replace with actual method to retrieve CSRF token
    return 'your_csrf_token_here';
}

export default function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [signupFailed, setSignUpFailed] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== rePassword) {
            setSignUpFailed('Passwords do not match');
            return;
        }

        const apiUrl = `${API_BASE_URL}auth/users/`;
        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('re_password', rePassword);

        try {
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'X-CSRFToken': getCsrfToken(),
                },
            });
            console.log('User Created', response.data);
            setUsername('');
            setEmail('');
            setPassword('');
            setRePassword('');
            setSignUpFailed('');

            alert('Please check email to activate account');
        } catch (error) {
            console.error('Registration failed', error.response?.data);
            let errorMessage = 'Registration failed. Please try again.'; // Default error message

            if (error.response && error.response.data) {
                if (error.response.data.username && error.response.data.username.includes('A user with that username already exists.')) {
                    errorMessage = 'A user with that username already exists';
                } else if (error.response.data.password && error.response.data.password[0].includes('The password is too similar to the username')) {
                    errorMessage = 'The password is too similar to the username and/or This password is too common.';
                } else if (error.response.data.email && error.response.data.email[0].includes('user with this email already exists.')) {
                    errorMessage = 'A user with that email already exists';
                }
            }

            setSignUpFailed(errorMessage); // Update UI with the appropriate message
        }
    };

    return (
        <>
            <div className="register-body">
                <div className="outer-register-body">
                    <div className="inner-register-body">
                        <div className="error-message">
                            {signupFailed && <p className="signup-fail">{signupFailed}</p>}
                        </div>
                        <div className="top-header">
                            <img src={aclesia_logo} alt="Aclesia Logo" id="header-image" />
                            <p className="fs-lg header-txt-1">Welcome to Aclesia!</p>
                            <p className="fs-md header-txt-2">Let's get started</p>
                        </div>
                        <form onSubmit={handleSubmit} className="bottom-header">
                            <div className="bottom-container">
                                <p className="fs-md bottom-txt">Email</p>
                                <input className="user-info-input-register" type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <p className="fs-md bottom-txt">Username</p>
                                <input className="user-info-input-register" maxLength="20" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                <p className="fs-md bottom-txt">Password</p>
                                <input className="user-info-input-register" type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <p className="fs-md bottom-txt">Reenter-Password</p>
                                <input className="user-info-input-register" type="password" name="repassword" id="repassword" value={rePassword} onChange={(e) => setRePassword(e.target.value)} />
                            </div>
                            <div className="signup-container-register">
                                <button className="signup-btn" id="signup-button" type="submit">Signup</button>
                            </div>

                            {/* Choice for User or Church */}
                            {/* <div className="church-user-choice">
                                <p className="fs-md">What type of account are you registering for:</p>
                                <div className="checkboxes">
                                    <label className="user-label fs-md">
                                        <input type="checkbox" name="church" id="user" value="user" className=""/>User
                                    </label>
                                    <label className="church-label fs-md">
                                        <input type="checkbox" name="church" id="church" value="church" className=""/>Church
                                    </label>
                                </div>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
