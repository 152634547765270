import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import aclesia_logo from "../images/aclesia-logo.png";
import search_icon from "../images/search-icon.svg"; // Make sure to import your search icon

export default function MobileNavbar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const search_navigate = useNavigate();

    const handleSearch = (event) => {
        event.preventDefault();
        search_navigate(`/search?search=${encodeURIComponent(searchTerm)}`);
    };

    const handleSearchClick = () => {
        setIsSearchActive(true);
    };

    return (
        <>
            <header className="responsive-header">
                <ul className="responsive-header-ul">
                    {isSearchActive ? (
                        <li className="responsive-header-item">
                            <form onSubmit={handleSearch} className="search-form">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    className="search-input"
                                />
                                <button type="submit" className="search-button">
                                    <img src={search_icon} alt="Search" />
                                </button>
                            </form>
                        </li>
                    ) : (
                        <>
                        <div className="mobile-header-container">
                            <div className="mobile-left">
                                <li className="responsive-header-item">
                                    <img
                                        id="responsive-header-img"
                                        src={aclesia_logo}
                                        alt="Aclesia Logo"
                                    />
                                </li>
                                <li className="responsive-header-item">
                                    <p id="responsive-header-text">ACLESIA</p>
                                </li>
                            </div>
                            <li className="responsive-header-item" onClick={handleSearchClick}>
                                <img id="search-icon-mobile"src={search_icon} alt="Search" />
                            </li>
                        </div>
                        </>
                    )}
                </ul>
            </header>

            <style jsx>{`
                .search-form {
                    display: flex;
                    align-items: center;
                }
                .search-input {
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    width: 200px; // Adjust width as necessary
                }
                .search-button {
                    background: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 8px;
                }
                .search-button img {
                    width: 24px; // Adjust icon size as necessary
                    height: 24px;
                }
            `}</style>
        </>
    );
}
