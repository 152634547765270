import "../styles/create.css";
import uploadIcon from "../images/upload-cloud-icon.png";
import upload from "../images/upload.svg";
import image from "../images/image.svg";
import { API_BASE_URL } from "../components/config.js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useCSRFToken from "../components/usecsrftoken.js";
import { decryptText } from "../components/crypto.js";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

export default function VideoPostForm() {
    const csrfToken = useCSRFToken();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [videoFileName, setVideoFileName] = useState("");
    const [thumbnailFileName, setThumbnailFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            const storedProfileStr = localStorage.getItem("userProfile");
            let storedProfile = null;
            try {
                storedProfile = JSON.parse(decryptText(storedProfileStr));
            } catch (error) {
                console.error(
                    "Error decrypting or parsing stored profile:",
                    error
                );
            }

            if (storedProfile) {
                try {
                    const response = await axios.get(
                        `${API_BASE_URL}profile/${storedProfile.id}`,
                        {
                            headers: {
                                Authorization: `JWT ${localStorage.getItem(
                                    "authToken"
                                )}`,
                            },
                        }
                    );
                    setProfile(response.data);
                } catch (error) {
                    console.error("Failed to fetch profile", error);
                    if (error.response && error.response.status === 404) {
                        navigate("/not-found");
                    }
                }
            }
        };
        fetchProfile();
    }, [navigate]);

    const handleUpload = async () => {
        if (!videoFile || !profile) {
            alert("Please log in again.");
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("video_file", videoFile);
        formData.append("user", profile.id);
        formData.append("profile", profile.id);
        if (thumbnail) formData.append("thumbnail", thumbnail);

        try {
            await axios.post(`${API_BASE_URL}video/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRFToken": csrfToken,
                    Authorization: `JWT ${localStorage.getItem("authToken")}`,
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(percentCompleted);
                },
            });
            alert("Video uploaded successfully!");
            navigate(`/${profile.user_type}/${profile.username}`);
        } catch (error) {
            console.error("Error uploading video:", error);
            alert("There was an error uploading the video.");
        } finally {
            setLoading(false);
            setVideoFile(null);
            setThumbnail(null);
            setProgress(0);
        }
    };

    // Function for chunked video upload
    const uploadInChunks = async (file) => {
        const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB per chunk
        let start = 0;
        const end = file.size;
        let chunkNumber = 1;

        while (start < end) {
            const chunk = file.slice(start, Math.min(start + CHUNK_SIZE, end));
            const formData = new FormData();
            formData.append("file", chunk);
            formData.append("chunkNumber", chunkNumber);
            formData.append("totalChunks", Math.ceil(end / CHUNK_SIZE));

            try {
                await axios.post(
                    `${API_BASE_URL}video/chunk-upload/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken": csrfToken,
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            );
                            setProgress(percentCompleted);
                        },
                    }
                );
                chunkNumber++;
            } catch (error) {
                console.error("Chunk upload failed:", error);
            }

            start += CHUNK_SIZE;
        }
    };

    if (loading) {
        return (
            <div className="loading-screen">
                <Spinner />
                <p>Upload Progress: {progress}%</p>
            </div>
        );
    }

    return (
        <div className="video-submit-container">
            <input
                id="video-content"
                name="video_file"
                type="file"
                onChange={(e) => {
                    if (e.target.files) {
                        setVideoFile(e.target.files[0]);
                        setVideoFileName(e.target.files[0].name);
                    }
                }}
                required
                hidden
            />
            <label htmlFor="video-content">
                <div className="post-container-image">
                    <img className="video-upload-icon" src={upload} alt="Video-Upload" />
                    <p className="upload-text">Select a Video File to Upload</p>
                    
                </div>
            </label>
            {videoFileName && (
                <p id="selected-video">Selected Video: {videoFileName}</p>
            )}

            <input
                id="thumbnail-content"
                name="thumbnail_file"
                type="file"
                onChange={(e) => {
                    if (e.target.files) {
                        setThumbnail(e.target.files[0]);
                        setThumbnailFileName(e.target.files[0].name);
                    }
                }}
                hidden
            />
            <label htmlFor="thumbnail-content">
                <div className="thumbnail-container-image">
                    <p className="thumbnail-text">Click to Upload Thumbnail</p>
                    <img
                        className="upload-icon"
                        src={image}
                        alt="Upload Thumbnail"
                    />
                </div>
            </label>
            {thumbnailFileName && (
                <p id="selected-thumbnail">
                    Selected Thumbnail: {thumbnailFileName}
                </p>
            )}

            <div className="create-input-container">
                
                <input
                    id="title-content"
                    name="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title"
                    required
                />

                
                    <textarea
                    id="description-content"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                    required
                    rows="5" // Optional: Specifies the number of visible lines
                    cols="50" // Optional: Specifies the visible width
                    />
                <button id="handle-upload-btn" onClick={handleUpload}>
                    Upload Video
                </button>
            </div>
        </div>
    );
}
