
import { API_BASE_URL } from "../components/config.js";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { encryptText, decryptText } from "../components/crypto.js";
import churchicon from "../images/church.svg";
import create_icon from "../images/create.svg";

export default function MobileFooter() {
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data
    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const fetchProfile = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}profiles/${storedProfile?.username}`, // Assuming username is in storedProfile
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem("authToken")}`,
                    },
                }
            );
            console.log(response.data);
            setProfile(response.data); // Just set the profile here
        } catch (error) {
            console.error("Failed to fetch profile", error);
            if (error.response && error.response.status === 404) {
                navigate("/not-found");
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            navigate("/login");
        } else {
            fetchProfile();
        }
    }, []); // Add the empty dependency array

    return (
        <>
            <footer className="bottom-navbar-small">
                <ul className="bottom-nav-ul">
                    <a href="/churches" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-church" src={churchicon} />
                        </li>
                    </a>
                    <a href="/create" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-create" src={create_icon} />
                        </li>
                    </a>
                    <a
                        href={`/${profile?.user_type}/${profile?.username}`}
                        className="bottom-nav-item"
                    >
                        <li>
                            <img
                                width="50"
                                height="50"
                                id="bottom-nav-profile"
                                src={`${
                                    profile?.profile_img?.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile?.profile_img
                                }`}
                                alt="Profile"
                            />
                        </li>
                    </a>
                </ul>
            </footer>
        </>
    );
}