import { VideoPlayer, VideoPlayerProps } from "@graphland/react-video-player";
import "../styles/watch.css";
import { useEffect, useState } from "react";

export default function VideoPlayers({ video }) {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateDimensions = (width) => {
    if (width > 1070) {
      return { width: 1070, height: 602 };
    } else {
      return { width, height: (width * 9) / 16 };
    }
  };

  const { width, height } = calculateDimensions(deviceWidth - 50);

  const videoSources = [
    {
      src: video,
      type: "video/mp4",
    },
    // Add more video sources as needed
  ];

  const videoProps = {
    theme: "fantasy", // 'city', 'fantasy', 'forest', 'sea'
    height: height,
    width: width,
    autoPlay: false,
    loop: false,
    sources: videoSources,
    controlBar: {
      skipButtons: {
        forward: 5,
        backward: 5,
      },
    },
    playbackRates: [0.5, 1, 1.5, 2],
    disablePictureInPicture: false,
    onReady: () => {
      // console.log("Video player is ready!");
    },
  };

  const videoContainerStyle = {
    borderRadius: "15px",
    overflow: "hidden", // Ensure content stays within the rounded border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: add a shadow for better visual
    width: `${width}px`, // Ensures container fits the video player
    margin: "0 auto", // Centers the video player horizontally
  };

  return (
    <div style={videoContainerStyle}>
      <VideoPlayer {...videoProps} />
    </div>
  );
}
