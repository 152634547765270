import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../components/config';

export default function DeleteUser() {
    const [statusMessage, setStatusMessage] = useState('');
    const [currentPassword, setCurrentPassword] = useState(''); // State to hold the current password

    const deleteUser = () => {
        const config = {
            headers: { Authorization: "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEzMTk4ODcwLCJpYXQiOjE3MTMxOTg1NzAsImp0aSI6Ijc4NTljM2FiZGU2MTQ3MGZiMWQ1ZDUyMzRhMDJjOTA1IiwidXNlcl9pZCI6NH0.kYNWJ-wzcwj9NEDc5tbAt4c-bsSwDt3DTGsFWiHtnGU" }, // Ensure the token is correctly prefixed
            data: { current_password: currentPassword } // Include the password in the data payload
        };

        axios.delete(`${API_BASE_URL}auth/users/me/`, config)
            .then(response => {
                console.log('User deleted successfully');
                setStatusMessage('User deleted successfully.');
            })
            .catch(error => {
                console.error('Error:', error.response ? error.response.data : 'Unknown error');
                setStatusMessage('Failed to delete user.');
            });
    };

    return (
        <>
            <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter your current password"
            />
            <button onClick={deleteUser}>Delete My Account</button>
            {statusMessage && <p>{statusMessage}</p>}
        </>
    );
}
