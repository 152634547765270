import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/config.js";
import { decryptText } from "../components/crypto.js";
import "../styles/leftbar.css";
import churchicon from "../images/church.svg";
import discovericon from "../images/discover.svg";
import comment from "../images/comment_solid_lg_grey.svg";

export default function LeftBar() {
    const [following, setFollowing] = useState([]);
    const [profiles, setProfiles] = useState({});
    const [loading, setLoading] = useState(true);
    const storedProfileStr = localStorage.getItem("userProfile");
    let storedProfile;

    try {
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }
    const token = localStorage.getItem("authToken");

    const username = storedProfile?.username;
    const username_id = storedProfile?.id;

    useEffect(() => {
        const fetchFollowingData = async () => {
            try {
                const authToken = localStorage.getItem("authToken");
                if (!authToken) {
                    throw new Error(
                        "No auth token found. User might need to log in again."
                    );
                }

                const config = {
                    headers: {
                        Authorization: `JWT ${authToken}`,
                    },
                };

                const res = await axios.get(
                    `${API_BASE_URL}followeduser/${username_id}`,
                    config, 
                    
                );
                console.log(res.data);
                setFollowing(res.data);

                const profilePromises = res.data.map((following) => {
                    if (following.user) {
                        return fetchProfileData(following.followed, config);
                    }
                    return Promise.reject(new Error("User ID is undefined"));
                });

                await Promise.all(profilePromises);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching following data:", err);
                setLoading(false);
            }
        };

        fetchFollowingData();
    }, [username]);

    const fetchProfileData = async (followedId, config) => {
        if (!profiles[followedId]) {
            try {
                const res = await axios.get(
                    `${API_BASE_URL}profile/${followedId}`,
                    config
                );
                const profileData = res.data;

                setProfiles((prevProfiles) => ({
                    ...prevProfiles,
                    [followedId]: {
                        username: profileData.username,
                        profileImg: profileData.profile_img,
                        user_type: profileData.user_type,
                    },
                }));
            } catch (err) {
                console.error("Error fetching profile data:", err);
            }
        }
    };

    if (loading) {
        return <div className="loading-screen"></div>;
    }

    return (
        <>
            <div className="leftbar-container">
                <a id="churches-link" href="/churches">
                    <div className="link-items-container">
                        <ul className="link-ul">
                            <li className="link-items" id="church-icon">
                                <img src={churchicon} alt="Church Icon" />
                            </li>
                            <li className="link-items" id="church-link">
                                Churches
                            </li>
                        </ul>
                    </div>
                </a>
                <a
                    id="feedback-link"
                    href="https://forms.gle/tqZ1EYdq7iK1fH2e7"
                    target="_blank"
                >
                    <div className="link-items-container">
                        <ul className="link-ul">
                            <li className="link-items" id="comment-icon">
                                <img src={comment} alt="comment-icon" />
                            </li>
                            <li className="link-items" id="feedback-link">
                                Feedback
                            </li>
                        </ul>
                    </div>
                </a>
                <hr id="line-break-1" />
                <a id="discover-churches-link" href="/churches">
                    <div className="discover-items-container">
                        <ul className="discover-link-ul">
                            <li
                                className="discover-link-items"
                                id="discover-icon"
                            >
                                <img src={discovericon} alt="Discover Icon" />
                            </li>
                            <li
                                className="discover-link-items"
                                id="discover-link"
                            >
                                Discover Churches
                            </li>
                        </ul>
                    </div>
                </a>
                <p id="following-text">Following</p>
                {following.map((followedId, id) => {
                    // Check if the followedId is not equal to the logged-in user's profile ID
                    if (followedId.followed !== storedProfile.id) {
                        return (
                            <div className="following-container" key={id}>
                                <ul className="following">
                                    <a
                                        className="following-link"
                                        href={`/${
                                            profiles[followedId.followed]
                                                ?.user_type
                                        }/${
                                            profiles[followedId.followed]
                                                ?.username
                                        }`}
                                    >
                                        <li className="following-items">
                                            <img
                                                id="church-profile-pic"
                                                src={
                                                    profiles[
                                                        followedId.followed
                                                    ]?.profileImg.startsWith(
                                                        "/https:/"
                                                    )
                                                        ? profiles[
                                                              followedId
                                                                  .followed
                                                          ]?.profileImg.replace(
                                                              "/https:/",
                                                              "https://"
                                                          )
                                                        : profiles[
                                                              followedId
                                                                  .followed
                                                          ]?.profileImg
                                                }
                                                alt="Profile"
                                            />
                                        </li>
                                        <li
                                            className="following-items"
                                            id="church-names"
                                        >
                                            {
                                                profiles[followedId.followed]
                                                    ?.username
                                            }
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        );
                    }
                    return null; // Skip rendering the logged-in user as following themselves
                })}
            </div>
        </>
    );
}
