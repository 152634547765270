import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../components/config.js";
import axios from "axios";
import Spinner from "../components/spinner";

import { encryptText, decryptText } from "../components/crypto.js";

import Navbar from "../components/navbar";
import UserVideo from "../components/uservideo.js";
import MobileNavbar from "../components/mobile-navbar.js";
import UserPost from "../components/userpost.js";
import LeftBar from "../components/leftbar";
import BackHome from "../components/backhome.js";
import InnerContainer from "../components/inner-container.js";
import "../styles/userprofile.css";
import aclesia_logo from "../images/aclesia-logo.png";
import churchicon from "../images/church.svg";
import create_icon from "../images/create.svg";

import left_arrow from "../images/left-arrow.svg";
import bell from "../images/bell.svg";
import chat from "../images/chat_user.svg";
import location from "../images/location.svg";
import house from "../images/Home.svg";
import users from "../images/Users.svg";
import dots from "../images/3dots.svg";

export default function UserProfile() {
    const navigate = useNavigate();
    const { username } = useParams();
    const [profile, setProfile] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [postType, setPostType] = useState("post"); // Default to text post
    const [followed_data, setFollowedData] = useState(); // Providing an empty array as default value
    const [followedUserData, setFollowedUserData] = useState([]);
    const config = {
        headers: {
            Authorization: `JWT ${localStorage.getItem("authToken")}`,
        },
    };

    // const [isFollowing, setIsFollowing] = useState(false);

    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            navigate("/login");
        } else {
            fetchProfile();
        }

        // Event handler for clicking outside of the dropdown
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [username, navigate]);

    const handleLogout = () => {
        // Simply remove the authToken from localStorage
        localStorage.removeItem("authToken");
        // Redirect to the login page
        navigate("/login");
    };

    const handleFollowToggle = async () => {
        try {
            // Check if the user is already following
            const isFollowing =
                followed_data &&
                followed_data.length > 0 &&
                followed_data[0].followed === profile.id;
    
            // If already following, unsubscribe
            if (isFollowing) {
                await axios.delete(
                    `${API_BASE_URL}followed/${profile.id}/${storedProfile.id}/`, // Added trailing slash here
                    config
                );
            } else {
                // If not following, subscribe
                await axios.post(
                    `${API_BASE_URL}follower/`,
                    {
                        user: storedProfile.id,
                        profile: profile.id,
                        followed: profile.id,
                    },
                    config
                );
            }
    
            // After subscribing or unsubscribing, fetch the updated followed data
            fetchFollowedData();
        } catch (error) {
            console.error("Error toggling follow status:", error);
        }
    };
    

    const fetchFollowedData = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}followed/${profile.id}/${storedProfile.id}`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setFollowedData(response.data);
        } catch (error) {
            console.error("Error fetching followed data:", error);
        }
    };

    const fetchProfile = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}profiles/${username}`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            console.log(response.data);
            setProfile(response.data); // Just set the profile here

        } catch (error) {
            console.error("Failed to fetch profile", error);
            if (error.response && error.response.status === 404) {
                navigate("/not-found");
            }
        }
    };

    useEffect(() => {
        if (profile) {
            // Ensure profile.id exists before fetching followed data
            fetchFollowedData(profile.id);
            fetchFollowedUserData();
        }
    }, [profile]); // Effect to run when 'profile' state changes

    const toggleDropdown = () => setIsOpen(!isOpen);

    if (!profile) {
        return (
            <>
                <div className="loading-screen"></div>
                <Spinner />
            </>
        );
    }

    const navigateToPage = () => {
        window.location.href = `/edit/${username}`;
    };

    document.querySelectorAll(".user-btn").forEach((button) => {
        button.addEventListener("click", function () {
            this.classList.toggle("clicked"); // Toggle the 'clicked' class on click
        });
    });

    const fetchFollowedUserData = async () => {
        if (!profile) return;

        try {
            const response = await axios.get(
                `${API_BASE_URL}followeduser/`, // Corrected URL by removing the `}`
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem("authToken")}`,
                    },
                }
            );
            console.log(response.data);
            setFollowedUserData(response.data);
        } catch (error) {
            console.error("Error fetching followed data:", error);
        }
    };


    const subscriberCount = followedUserData
        ? followedUserData.filter((follow) => follow.followed === profile.id).length
        : 0;

    return (
        <>
            <Navbar />
            <LeftBar />
            <MobileNavbar />
            <div className="user-profile-container-userprofile">
                <div className="user-profile-body">
                    <BackHome />

                    <div className="user-info-section">
                        <ul className="user-info-ul">
                            <li className="user-info-items">
                                <img
                                    id="user-profile-img"
                                    src={`${
                                        profile.profile_img.startsWith(
                                            "/https:/"
                                        )
                                            ? profile.profile_img.replace(
                                                  "/https:/",
                                                  "https://"
                                              )
                                            : profile.profile_img
                                    }`}
                                    alt={`${username}'s Profile`}
                                />
                            </li>
                            <li className="user-info-items">
                                <ul className="user-information">
                                    <li className="user-information-items">
                                        <ul className="user-information-items-ul">
                                            <div className="responsive-username-subscribe">
                                                <li
                                                    className="user-information-items-ul-items"
                                                    id="user-name"
                                                >
                                                    {username}
                                                </li>
                                                {storedProfile.username !==
                                                    username && (
                                                    <li className="user-information-items-ul-items subscribe-message">
                                                        <button
                                                            className="profile-btn"
                                                            onClick={
                                                                handleFollowToggle
                                                            }
                                                            id="subscribe-btn"
                                                        >
                                                            <ul className="subscribe-btn-ul">
                                                                {followed_data &&
                                                                followed_data.length >
                                                                    0 &&
                                                                followed_data[0]
                                                                    .followed ===
                                                                    profile.id ? (
                                                                    <li className="subscribe-items profile-text">
                                                                        Subscribed
                                                                    </li>
                                                                ) : (
                                                                    <li className="subscribe-items profile-text">
                                                                        Subscribe
                                                                    </li>
                                                                )}

                                                                <li className="subscribe-items">
                                                                    <img
                                                                        src={
                                                                            bell
                                                                        }
                                                                        alt="Subscribe"
                                                                    />
                                                                </li>
                                                            </ul>
                                                        </button>
                                                    </li>
                                                )}
                                            </div>

                                            {/* {storedProfile.username !== username && (
                                            <li className="user-information-items-ul-items subscribe-message">
                                                
                                                <button className="profile-btn" id="message-btn">
                                                    <ul className="message-btn-ul">
                                                        <li className="message-items profile-text">Message</li>
                                                        <li className="message-items"><img src={chat} alt="Message" /></li>
                                                    </ul>
                                                </button>
                                                
                                            </li>
                                            )} */}

                                            <li className="user-information-items-ul-items dots-container">
                                                {storedProfile.username ===
                                                    username && (
                                                    <div ref={dropdownRef}>
                                                        <button
                                                            className="dots-button"
                                                            onClick={
                                                                toggleDropdown
                                                            }
                                                        >
                                                            <div className="dots-container">
                                                                <img
                                                                    src={dots}
                                                                    alt="Menu"
                                                                />
                                                            </div>
                                                        </button>
                                                        {isOpen && (
                                                            <div className="dropdown-menu">
                                                                <ul>
                                                                    <li className="edit-menu">
                                                                        <button
                                                                            id="edit-profile-button-edit"
                                                                            onClick={
                                                                                navigateToPage
                                                                            }
                                                                        >
                                                                            Edit
                                                                            Profile
                                                                        </button>
                                                                        <button
                                                                            id="edit-profile-button-signup"
                                                                            onClick={
                                                                                handleLogout
                                                                            }
                                                                        >
                                                                            Sign
                                                                            Out
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="user-information-items">
                                        <ul className="user-data-ul">
                                            <li className="user-data-ul-items">
                                                <ul className="location-ul">
                                                    <li className="location-items user-data-icons">
                                                        <img
                                                            className="user-data-img"
                                                            src={location}
                                                            alt="Location"
                                                        />
                                                    </li>
                                                    <li className="location-items user-data-items-txt">
                                                        {profile.location}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="user-data-ul-items">
                                                <ul className="denomination-ul">
                                                    <li className="denomination-items user-data-icons">
                                                        <img
                                                            className="user-data-img"
                                                            src={house}
                                                            alt="House"
                                                        />
                                                    </li>
                                                    <li className="denomination-items user-data-items-txt">
                                                        {profile.denomination}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="user-data-ul-items">
                                                <ul className="subscriber-ul">
                                                    <li className="subscriber-items user-data-icons">
                                                        <img
                                                            className="user-data-img"
                                                            src={users}
                                                            alt="Subscribers"
                                                        />
                                                    </li>
                                                    <li className="location-items user-data-items-txt">
                                                        {subscriberCount}{" "}
                                                        Subscribers
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="user-information-items user-bio">
                                        <div className="user-bio-div">
                                            <p className="user-bio-txt">
                                                {profile.bio}
                                            </p>
                                        </div>
                                    </li>
                                    <ul className="givefeedback-btn-ul">
                                        <a
                                            id="feedback-link"
                                            href="https://forms.gle/tqZ1EYdq7iK1fH2e7"
                                            target="_blank"
                                        >
                                            <li className="subscribe-items profile-text">
                                                Give Feedback
                                            </li>
                                        </a>
                                    </ul>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="user-video-post-container">
                    <div className="user-button-container">
                        <button
                            className="user-btn"
                            onClick={() => setPostType("text")}
                        >
                            Video
                        </button>
                        <button
                            className="user-btn"
                            onClick={() => setPostType("video")}
                        >
                            Post
                        </button>
                        <div className="divider-container">
                            <div className="divider"></div>
                        </div>

                        {postType === "video" ? <UserPost /> : <UserVideo />}
                    </div>
                </div>
            </div>
            <footer className="bottom-navbar-small">
                <ul className="bottom-nav-ul">
                    <a href="/churches" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-church" src={churchicon} />
                        </li>
                    </a>
                    <a href="/create" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-create" src={create_icon} />
                        </li>
                    </a>
                    <a
                        href={`/${profile.user_type}/${username}`}
                        className="bottom-nav-item"
                    >
                        <li>
                            <img
                                width="50"
                                height="50"
                                id="bottom-nav-profile"
                                src={`${
                                    profile.profile_img.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile.profile_img
                                }`}
                                alt="Profile"
                            />
                        </li>
                    </a>
                </ul>
            </footer>
        </>
    );
}
