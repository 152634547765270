import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/config.js";

import dots from "../images/3dots.svg";

import { encryptText, decryptText } from "../components/crypto.js";

export default function UserPost() {
  const [details, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

  let storedProfile;
  try {
    // Attempt to decrypt and parse the stored data
    storedProfile = JSON.parse(decryptText(storedProfileStr));
  } catch (error) {
    // Handle decryption or parsing errors
    console.error("Error decrypting or parsing stored profile:", error);
    storedProfile = null;
  }

  const username = storedProfile?.username;
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}posts/${username}`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        // You might want to handle this error more gracefully in your UI
      });
  }, []); // Empty dependency array means this effect runs only once after the initial rendering

  const handleDelete = (videoId) => {
    axios
      .delete(`${API_BASE_URL}post/${videoId}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        alert("Post deleted");
        // Refresh the list after deleting
        setDetails(details.filter((item) => item.id !== videoId));
      })

      .catch((err) => {
        console.error("Error deleting video:", err);
        alert("Error deleting the post: " + err.message);
        // Handle the error more gracefully
      });
  };

  const toggleDropdown = (id) => {
    setIsOpen((prev) => ({ ...prev, [id]: !prev[id] })); // Toggle the specific dropdown
  };
  return (
    <>
      <div className="user-video-container">
        <div className="post-items">
          {details.map((output, id) => (
            <div className="post-div" key={id}>
              <button
                className="dots-button-post"
                onClick={() => toggleDropdown(output.id)}
              >
                <div className="dots-container-post">
                  <img src={dots} alt="Menu" />
                </div>
              </button>
              <a id="post-link-item" href="www.google.com">
                <h1 id="post-title">{output.title}</h1>
                <p className="post-content">{output.post_content}</p>
              </a>

              {isOpen[output.id] && ( // Check the state for each specific video
                <div className="dropdown-menu-post">
                  <ul>
                    <li className="edit-menu">
                      <button
                        id="edit-profile-button"
                        onClick={() => handleDelete(output.id)}
                      >
                        Delete Post
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
