import React, { useState } from "react";
import axios from "axios";
import "../styles/login.css";
import aclesia_logo from "../images/aclesia-logo.svg";
import { API_BASE_URL } from "../components/config.js";
import { useNavigate } from "react-router-dom";
import { encryptText } from "../components/crypto.js";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const navigate = useNavigate();
    const [loginFailed, setLoginFailed] = useState("");
    const [resetMessage, setResetMessage] = useState("");

    const handleLogin = async (event) => {
        event.preventDefault();
        localStorage.clear();

        try {
            const response = await axios.post(
                `${API_BASE_URL}auth/jwt/create`,
                {
                    username,
                    password,
                }
            );
            localStorage.setItem("authToken", response.data.access);

            // Fetch user data before navigating
            await fetchUserData();

            navigate("/");
        } catch (error) {
            setLoginFailed(
                "Login Failed: Username or Password Incorrect. Please Try Again."
            );
            console.error("Login failed:", error.response);
        }
    };

    const fetchUserData = async () => {
        try {
            const userResponse = await axios.get(
                `${API_BASE_URL}auth/users/me/`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            const encryptedUserData = encryptText(
                JSON.stringify(userResponse.data)
            );
            localStorage.setItem("userProfile", encryptedUserData);
        } catch (error) {
            console.error("Error fetching user data:", error);
            // You can also set an error state here if needed
        }
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(
                `${API_BASE_URL}auth/users/reset_password/`,
                {
                    email,
                    //   headers: {
                    //     Authorization: `JWT ${localStorage.getItem("authToken")}`,
                    //   },
                }
            );

            setResetMessage("Password reset email has been sent.");
        } catch (error) {
            setResetMessage("An error occurred. Please try again.");
            console.error("Password reset failed:", error.response);
        }
    };

    return (
        <div className="body-container">
            <div className="inside-body">
                <div className="left-field-container">
                    <p className="fs-launch">Launching 2024</p>
                </div>
                <div className="right-field-container">
                    <div className="login-failed">
                        {loginFailed && (
                            <p className="error-message">{loginFailed}</p>
                        )}
                    </div>
                    <div className="header-right-field">
                        <img
                            className="main-logo"
                            src={aclesia_logo}
                            alt="Aclesia Logo"
                        />
                        <p className="fs-lg welcome-text">Welcome to Aclesia</p>
                    </div>
                    {isForgotPassword ? (
                        <div className="forgot-password-container">
                            <p className="fs-md input-text">Email</p>
                            <input
                                className="user-info-input"
                                name="email"
                                type="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                                id="reset-button"
                                type="submit"
                                onClick={handleForgotPassword}
                            >
                                Send Reset Link
                            </button>
                            {resetMessage && (
                                <p className="reset-message">{resetMessage}</p>
                            )}
                            <a
                                className="back-to-login-link"
                                onClick={() => setIsForgotPassword(false)}
                            >
                                Back to Login
                            </a>
                        </div>
                    ) : (
                        <div className="user-info">
                            <p className="fs-md input-text">Username</p>
                            <input
                                className="user-info-input"
                                name="username"
                                id="username"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <p className="fs-md input-text">Password</p>
                            <div className="login-block">
                                <input
                                    className="user-info-input"
                                    name="password"
                                    type="password"
                                    id="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <button
                                    id="login-button"
                                    type="submit"
                                    onClick={handleLogin}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    )}
                    {!isForgotPassword && (
                        <>
                            <a className="sign-up-button" href="/register">
                                <div className="sign-up-button-container">
                                    Sign Up
                                </div>
                            </a>
                            <a
                                className="forgot-password-link"
                                onClick={() => setIsForgotPassword(true)}
                            >
                                <div className="forgot-password-container">
                                    Forgot Password
                                </div>
                            </a>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
