import "../styles/edit.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/navbar";
import LeftBar from "../components/leftbar";
import axios from "axios";
import { API_BASE_URL } from "../components/config.js";
import useCSRFToken from "../components/usecsrftoken.js";
import { encryptText, decryptText } from "../components/crypto.js";
import React, { useEffect, useState } from "react";
import aclesia_logo from "../images/aclesia-logo.png";
import churchicon from "../images/church.svg";
import create_icon from "../images/create.svg";

export default function Edit() {
    const navigate = useNavigate();
    const csrfToken = useCSRFToken();
    const { username } = useParams();

    const [profile, setProfile] = useState(null);
    const [profile_img, setProfileImage] = useState(null);
    const [edit_bio, setEditBio] = useState("");
    const [edit_location, setEditLocation] = useState("");
    const [edit_denomination, setEditDenomination] = useState("");
    const [edit_homechurch, setEditHomeChurch] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            navigate("/login"); // Redirect to login if no token is found
        } else {
            fetchProfile();
        }
    }, []);

    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const fetchProfile = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}profile/${storedProfile.id}/`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setProfile(response.data);
        } catch (error) {
            console.error("Failed to fetch profile", error);
            if (error.response && error.response.status === 404) {
                navigate("/not-found");
            }
        }
    };

    if (!profile) {
        return <div>Loading...</div>;
    }

    const url_tag = profile.user_type === "user" ? "user" : "church";

    const handleEdit = async () => {
        const formData = new FormData();

        if (profile_img) {
            formData.append("profile_img", profile_img);
        }
        if (edit_bio) {
            formData.append("bio", edit_bio);
        }
        if (edit_location) {
            formData.append("location", edit_location);
        }
        if (edit_denomination) {
            formData.append("denomination", edit_denomination);
        }
        if (edit_homechurch) {
            formData.append("home_church", edit_homechurch);
        }

        formData.append("user", storedProfile.id); // Ensure this is an integer or a string that represents an integer

        try {
            const response = await axios.put(
                `${API_BASE_URL}profile/${storedProfile.id}/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-CSRFToken": csrfToken,
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            alert("Profile updated!");
            navigate(`/${url_tag}/${username}`);
        } catch (error) {
            if (error.response) {
                console.error("Server responded with:", error.response.data);
                alert(
                    `Error Updating Profile: ${error.response.status} ${error.response.data.detail}`
                );
            } else if (error.request) {
                console.error(
                    "The request was made but no response was received"
                );
                alert("Error: No response from server");
            } else {
                console.error("Error setting up the request:", error.message);
                alert("Error: " + error.message);
            }
        }
    };

    return (
        <>
            <Navbar />
            <LeftBar />
            <header className="responsive-header">
                <ul className="responsive-header-ul">
                    <li className="responsive-header-item">
                        <img
                            id="responsive-header-img"
                            src={aclesia_logo}
                            alt="Aclesia Logo"
                        />
                    </li>
                    <li className="responsive-header-item">
                        <p id="responsive-header-text">ACLESIA</p>
                    </li>
                </ul>
            </header>

            <div className="edit-container">
                <h1 className="header-txt">Edit Profile</h1>
                <div className="outer-edit-body">
                    <div className="inner-edit-body">
                        <div className="edit-profile-img-body">
                            <div className="edit-profile-image-container">
                                <ul className="edit-profile-img-ul">
                                    <li className="edit-profile-img-item">
                                        <img
                                            width="100"
                                            height="100"
                                            id="current-profile-img"
                                            src={`${
                                                profile.profile_img.startsWith(
                                                    "/https:/"
                                                )
                                                    ? profile.profile_img.replace(
                                                          "/https:/",
                                                          "https://"
                                                      )
                                                    : profile.profile_img
                                            }`}
                                            alt="Current Profile"
                                        />
                                    </li>
                                    <li className="edit-profile-img-item">
                                        <div>
                                            <input
                                                id="edit-profile-img"
                                                name="edit-profile-img"
                                                type="file"
                                                onChange={(e) =>
                                                    setProfileImage(
                                                        e.target.files[0]
                                                    )
                                                }
                                                required
                                                hidden
                                            />
                                            <label htmlFor="edit-profile-img">
                                                <div className="edit-img-btn">
                                                    <p className="edit-img-txt">
                                                        Edit Profile Image
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="edit-bio-body">
                            <div className="edit-profile-bio">
                                <p className="edit-bio-txt">Edit Bio</p>
                                <textarea
                                    id="edit-bio"
                                    name="edit-bio"
                                    value={edit_bio}
                                    onChange={(e) => setEditBio(e.target.value)}
                                    maxLength="300"
                                    rows="4"
                                    cols="50"
                                    required
                                />
                            </div>
                        </div>
                        <div className="edit-location-body">
                            <div className="edit-profile-location">
                                <p className="edit-location-txt">
                                    Edit Location
                                </p>
                                <textarea
                                    id="edit-location"
                                    name="edit-location"
                                    value={edit_location}
                                    onChange={(e) =>
                                        setEditLocation(e.target.value)
                                    }
                                    maxLength="100"
                                    rows="4"
                                    cols="50"
                                    required
                                />
                            </div>
                        </div>
                        <div className="edit-denomination-body">
                            <div className="edit-profile-denomination">
                                <p className="edit-denomination-txt">
                                    Edit Denomination
                                </p>
                                <textarea
                                    id="edit-denomination"
                                    name="edit-denomination"
                                    value={edit_denomination}
                                    onChange={(e) =>
                                        setEditDenomination(e.target.value)
                                    }
                                    maxLength="100"
                                    rows="4"
                                    cols="50"
                                    required
                                />
                            </div>
                        </div>
                        <div className="edit-homechurch-body">
                            <div className="edit-profile-homechurch">
                                <p className="edit-homechurch-txt">
                                    Edit Home Church
                                </p>
                                <textarea
                                    id="edit-homechurch"
                                    name="edit-homechurch"
                                    value={edit_homechurch}
                                    onChange={(e) =>
                                        setEditHomeChurch(e.target.value)
                                    }
                                    maxLength="100"
                                    rows="4"
                                    cols="50"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="edit-btn-body-container">
                    <div className="edit-btn-container">
                        <button id="handle-edit-btn" onClick={handleEdit}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
            <footer className="bottom-navbar-small">
                <ul className="bottom-nav-ul">
                    <a href="/churches" className="bottom-nav-item">
                        <li>
                            <img
                                id="bottom-nav-church"
                                src={churchicon}
                                alt="Church Icon"
                            />
                        </li>
                    </a>
                    <a href="/create" className="bottom-nav-item">
                        <li>
                            <img
                                id="bottom-nav-create"
                                src={create_icon}
                                alt="Create Icon"
                            />
                        </li>
                    </a>
                    <a
                        href={`/${profile.user_type}/${username}`}
                        className="bottom-nav-item"
                    >
                        <li>
                            <img
                                width="50"
                                height="50"
                                id="bottom-nav-profile"
                                src={`${
                                    profile.profile_img.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile.profile_img
                                }`}
                                alt="Profile"
                            />
                        </li>
                    </a>
                </ul>
            </footer>
        </>
    );
}
