import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../components/config';
import '../styles/activation.css';
import Spinner from '../components/spinner';

export default function ActivationPage() {
    const { uid, token } = useParams();
    const [activationStatus, setActivationStatus] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const activateAccount = async () => {
        setLoading(true);
        try {
            const url = `${API_BASE_URL}auth/users/activation/`;
            await axios.post(url, { uid, token });
            setActivationStatus('Your account has been successfully activated!');
            setTimeout(() => navigate('/login'), 3000); // Redirect to /login after 3 seconds
        } catch (error) {
            setActivationStatus('There was an error activating your account. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Spinner />;

    return (
        <div className="activation-body">
            <h1 className="main-txt">Welcome to Aclesia!</h1>
            <h2 className="main-txt-2">Activate your Account by clicking the button below</h2>
            <p className="main-txt-3">{activationStatus}</p>
            <button id="activation-button" type="submit" onClick={activateAccount}>
                Activate
            </button>
        </div>
    );
}
