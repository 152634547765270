import '../styles/backhome.css';
import left_arrow from '../images/left-arrow.svg'


export default function BackHome() {


    return (

        <>
                <div className="backhome-body">
                    <a id="backhome-body-link" href="/">
                        <ul className="backhome-link">
                            <li id="backhome-icon"><img id="left-arrow-img" src={left_arrow} alt="Back Home"/></li>
                            <li id="backhome-txt">Back Home</li>
                        </ul>
                    </a>
                </div>
        
        </>
    )
}