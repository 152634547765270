import "../styles/create.css";
import upload_icon from "../images/upload-cloud-icon.png";
import { API_BASE_URL } from "../components/config.js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useCSRFToken from "../components/usecsrftoken.js";
import { encryptText, decryptText } from "../components/crypto.js";
import { useNavigate } from "react-router-dom";

export default function TextPostForm() {
  const csrfToken = useCSRFToken();
  const [title, setTitle] = useState("");
  const [post_content, setPostContent] = useState("");
  const [post_image, setPostImage] = useState(null); // Use null for initial state if it's a file
  const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data
  const [PostFileName, setPostFileName] = useState("");
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false); // Initialize loading as false
  const navigate = useNavigate(); // Create navigate instance

  let storedProfile;
  try {
    // Attempt to decrypt and parse the stored data
    storedProfile = JSON.parse(decryptText(storedProfileStr));
  } catch (error) {
    // Handle decryption or parsing errors
    console.error("Error decrypting or parsing stored profile:", error);
    storedProfile = null;
  }

  useEffect(() => {
    fetchProfile();
  }, []); // This ensures fetchProfile is called only once when the component mounts.

  const fetchProfile = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}profile/${storedProfile.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setProfile(response.data); // Just set the profile here
    } catch (error) {
      console.error("Failed to fetch profile", error);
      if (error.response && error.response.status === 404) {
        navigate("/not-found");
      }
    }
  };

  const handleUpload = async () => {
    if (!storedProfile) {
      console.error("No user profile available.");
      alert("Please log in again.");
      return; // Stop the function execution
    }
  
    setLoading(true); // Set loading to true when upload starts
    const formData = new FormData();
  
    if (post_image) {
      formData.append("post_image", post_image);
    }
    formData.append("title", title);
    formData.append("post_content", post_content);
    formData.append("user", storedProfile.id); // Ensure this is an integer or a string that represents an integer
    formData.append("profile", storedProfile.id); // Same as above
  
    try {
      const response = await axios.post(`${API_BASE_URL}post/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
          Authorization: `JWT ${localStorage.getItem("authToken")}`, // Moved here from the headers object inside another object
        },
      });
      alert("Post uploaded successfully!");
    } catch (error) {
      if (error.response) {
        console.error("Server responded with:", error.response.data);
        alert(
          `Error uploading post: ${error.response.status} ${error.response.data.detail}`
        );
      } else if (error.request) {
        console.error("The request was made but no response was received");
        alert("Error: No response from server");
      } else {
        console.error("Error setting up the request:", error.message);
        alert("Error: " + error.message);
      }
    } finally {
      setLoading(false); // Set loading to false when upload completes or fails
      fetchProfile();
      navigate(`/${profile.user_type}/${storedProfile.username}`); // Redirect to /home on success
    }
  };
  

  if (loading) {
    return <div className="loading-screen"></div>;
  }

  return (
    <div className="post-submit-container">
      <input
        id="post_image"
        name="post_image"
        type="file"
        onChange={(e) => {
          setPostImage(e.target.files[0]);
          setPostFileName(e.target.files[0].name);
        }}
        required
        hidden
      />

      <label htmlFor="post_image">
        <div className="post-container-image">
          <p className="upload-text">Click to Upload Post</p>
          <img className="cloud-icon" src={upload_icon} />
        </div>
      </label>

      {PostFileName && (
        <p id="selected-post">Selected Post Image: {PostFileName}</p>
      )}
      <div className="create-input-container">
        <label id="label-title" htmlFor="title-content">
          Title
        </label>
        <input
          id="title-content"
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <label id="label-content" htmlFor="post-content">
          Post Content
        </label>
        <input
          id="post-content"
          name="post-content"
          type="text"
          value={post_content}
          onChange={(e) => setPostContent(e.target.value)}
          required
        />
        <button id="handle-upload-btn" onClick={handleUpload}>
          Upload Post
        </button>
      </div>
    </div>
  );
}
