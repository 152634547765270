import CryptoJS from 'crypto-js';

const secretKey = 'AclesiaKey'; // Consider managing the key securely

export const encryptText = (plainText) => {
  return CryptoJS.AES.encrypt(plainText, secretKey).toString();
};

export const decryptText = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
