import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import LeftBar from '../components/leftbar';

export default function Home() {
    
    
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            navigate('/login');  // Redirect to login if no token is found
        }
        
    }, []);





    

    return (
        <>
            <Navbar />
            <LeftBar />
        </>
    );
}
