import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../components/config.js';
import Navbar from "../components/navbar";
import LeftBar from "../components/leftbar";
import MobileFooter from '../components/mobile-footer.js';
import MobileNavbar from '../components/mobile-navbar.js';
import BackHome from "../components/backhome";

import "../styles/search.css";


const CombinedSearch = () => {
    const [results, setResults] = useState({ profiles: [], videos: [], posts: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const searchTerm = new URLSearchParams(location.search).get('search'); // Change 'query' to 'search'

    useEffect(() => {
        if (searchTerm) {
            const fetchData = async () => {
                setLoading(true);
                setError(null);

                try {
                    const response = await axios.get(`${API_BASE_URL}search/?search=${searchTerm}`);
                    console.log(response.data); // Ensure response structure is correct
                    setResults({
                        profiles: response.data.profiles || [],
                        videos: response.data.videos || [],
                        posts: response.data.posts || [],
                    });
                } catch (err) {
                    setError('Error fetching search results');
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [searchTerm]);

    return (
        <div>
            <Navbar />
            <LeftBar />
            <MobileNavbar />
            <MobileFooter />
            

            <div className="user-profile-container-watch">
            <BackHome />
                            <h1>Search Results for "{searchTerm}"</h1>
                            {loading && <p>Loading...</p>}
                            {error && <p>{error}</p>}

                            <div className="search-results-container">

                                <ul>
                                    {results.profiles.map((profile) => (

                                        <a id="search-profile-link"href={`/user/${profile.username}`}>
                                            <div className="search-profile-container">
                                                <img id="search-profile-picture"src={profile.profile_img}/>
                                                <p id="search-profile-username">{profile.username}</p>
                                                    {/* <li key={profile.id}>
                                                        <h3>{profile.id}</h3>
                                                        <h3>{profile.user}</h3>
                                                        <p>{profile.bio}</p>
                                                    </li> */}




                                            </div>
                                            <div className="search-profile-container-mobile">
                                                <img id="search-profile-picture"src={profile.profile_img}/>
                                                <p id="search-profile-username">{profile.username}</p>
                                                    {/* <li key={profile.id}>
                                                        <h3>{profile.id}</h3>
                                                        <h3>{profile.user}</h3>
                                                        <p>{profile.bio}</p>
                                                    </li> */}




                                            </div>
                                        </a>

                                    ))}
                                </ul>


                                <ul className="large-video-search-class">

                                    {results.videos.map((video) => (
                                        <a href={`/watch/${video.id}`} id="video-search-link">
                                            <div className="search-video-container">
                                                <img id="search-video-thumbnail"src={video.thumbnail} />
                                                <div className="search-video-right">
                                                    <p id="search-video-title">{video.title}</p>
                                                    <ul className="search-video-img-username">
                                                        <li><img id="video-profile-img"src={video.profile.profile_img}/></li>
                                                        <li id="search-video-username">{video.profile.username}</li>
                                                    </ul>

                                                </div>

                                                <div className="search-video-right-mobile">
                                                     <ul className="search-video-img-username">
                                                        <li><img id="video-profile-img"src={video.profile.profile_img}/></li>
                                                        <li><p id="search-video-title">{video.title}</p></li>
    
                                                    </ul>
                                                    


                                                </div>
                                            </div>
                                    
                                        </a>
                                        // <li key={video.id}>
                                        //     <h3>{video.title} (ID: {video.id})</h3> {/* Display ID */}
                                        //     <p>{video.description}</p>
                                        // </li>
                                    ))}
                                </ul>

                                {/* <h2>Posts</h2>
                                <ul>
                                    {results.posts.map((post) => (
                                        <li key={post.id}>
                                            <p>{post.content}</p>
                                        </li>
                                    ))}
                                </ul> */}
                            </div>
                        </div>

            </div>

            

    );
};

export default CombinedSearch;
