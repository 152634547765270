import { API_BASE_URL } from "../components/config.js";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function useCSRFToken() {
  const [csrfToken, setCSRFToken] = useState("");

  useEffect(() => {
    async function fetchCSRFToken() {
      try {
        const { data } = await axios.get(`${API_BASE_URL}csrf/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("authToken")}`,
          },
        }); // Adjust the URL based on your configuration
        setCSRFToken(data.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    }

    fetchCSRFToken();
  }, []);

  return csrfToken;
}
