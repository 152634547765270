import React from 'react';
import '../styles/Spinner.css'; // assuming your CSS is in this file


const Spinner = () => (
    <div className="loader-body">
        <div className="loader"></div>
    </div>
);

export default Spinner;
